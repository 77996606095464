<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group class="mr-1">
                <b-form-input
                  v-model="searchQuery"
                  @keyup.enter="searchWord()"
                  :placeholder="$t('message.bookmark.search')"
                />
                <b-input-group-append>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                    @click="searchWord()"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <div>
                <flat-pickr
                  v-model="daySelected"
                  class="form-control"
                  :config="flatConfig"
                  @input="daySelectedFilter()"
                  :placeholder="$t('message.calendar.date')"
                />
              </div>

              <div>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon"
                  @click="daySelected = ''"
                >
                  <feather-icon icon="RefreshCcwIcon" />
                </b-button>
              </div>

              <b-dropdown
                right
                variant="gradient-warning"
                class="ml-1"
                v-if="restrictions('button_export_data')"
              >
                <template slot="button-content">
                  <feather-icon icon="DownloadIcon" />
                </template>
                <b-dropdown-item
                  ><download-excel
                    :data="pointList"
                    :before-generate="startDownload"
                    :before-finish="finishDownload"
                    :name="$t('message.buttons.dataInView')"
                  >
                    {{ $t("message.buttons.dataInView") }}
                  </download-excel></b-dropdown-item
                >
                <b-dropdown-item
                  ><download-excel
                    :fetch="allData"
                    :before-generate="startDownload"
                    :before-finish="finishDownload"
                    :name="$t('message.buttons.allData')"
                  >
                    {{ $t("message.buttons.allData") }}
                  </download-excel></b-dropdown-item
                >
              </b-dropdown>

              <b-button
                class="ml-1"
                variant="primary"
                v-if="isBusyButton"
                disabled
              >
                <span class="text-nowrap">{{
                  $t("message.buttons.newPoint")
                }}</span>
              </b-button>

              <b-button
                class="ml-1"
                @click="getUserLocation"
                variant="primary"
                v-b-modal.modal-new-point
                v-else
              >
                <span class="text-nowrap">{{
                  $t("message.buttons.newPoint")
                }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        id="my-table"
        ref="refpointListTable"
        class="position-relative"
        :items="pointList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :busy="isBusy"
        show-empty
        @row-clicked="openViewPage"
        :tbody-tr-class="rowClass"
        hover
      >
        <template #table-busy>
          <div class="text-center my-17">
            <b-spinner class="align-middle"></b-spinner>
            <strong> {{ $t("message.load") }}...</strong>
          </div>
        </template>

        <template slot="empty">
          <div class="text-center my-5">
            {{ $t("message.registerNotFound") }}
          </div>
        </template>

        <!-- Column: user -->
        <template #cell(user)="data">
          {{ data.item.userName }}
        </template>

        <!-- Column: Date -->
        <template #cell(date)="data">
          {{ getDay(new Date(data.item.date)) }}
        </template>

        <!-- Column: startDay -->
        <template #cell(startDay)="data">
          <a v-if="data.item.startDay">
            <span
              style="color: green"
              v-if="data.item.locationStartDay.latitude != ''"
              :id="'startDay-' + data.item.id"
            >
              <b-tooltip
                custom-class="long-tooltip"
                :target="'startDay-' + data.item.id"
                triggers="hover"
                no-fade
              >
                {{ $t("activeLocation") }}
              </b-tooltip>
              {{ getHour(new Date(data.item.startDay)) }}
            </span>
            <span
              style="color: red"
              v-else
              :id="'startDayInactive-' + data.item.id"
            >
              <b-tooltip
                custom-class="long-tooltip"
                :target="'startDayInactive-' + data.item.id"
                triggers="hover"
                no-fade
              >
                {{ $t("inactiveLocation") }}
              </b-tooltip>
              {{ getHour(new Date(data.item.startDay)) }}
            </span>
          </a>
          <a v-else> {{ $t("notDone") }} </a>
        </template>

        <!-- Column: endDay -->
        <template #cell(endDay)="data">
          <a v-if="data.item.endDay">
            <span
              style="color: green"
              v-if="data.item.locationEndDay.latitude != ''"
              :id="'endtDay-' + data.item.id"
            >
              <b-tooltip
                custom-class="long-tooltip"
                :target="'endtDay-' + data.item.id"
                triggers="hover"
                no-fade
              >
                {{ $t("activeLocation") }}
              </b-tooltip>
              {{ getHour(new Date(data.item.endDay)) }}
            </span>
            <span
              style="color: red"
              v-else
              :id="'endDayInactive-' + data.item.id"
            >
              <b-tooltip
                custom-class="long-tooltip"
                :target="'endDayInactive-' + data.item.id"
                triggers="hover"
                no-fade
              >
                {{ $t("inactiveLocation") }}
              </b-tooltip>
              {{ getHour(new Date(data.item.endDay)) }}
            </span>
          </a>
          <a v-else> {{ $t("notDone") }} </a>
        </template>

        <!-- Column: total -->
        <template #cell(total)="data">
          <a v-if="data.item.total">
            {{ data.item.total }}
          </a>
          <a v-else> {{ $t("notFinished") }} </a>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("message.pagelength") }} {{ dataMeta.from }}
              {{ $t("message.of") }} {{ dataMeta.to }} {{ $t("message.of") }}
              {{ totalRegisters }} {{ $t("register") }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            v-if="showPagination"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRegisters"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
              @input="eventPage(currentPage)"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-new-point"
      modal-class="modal-primary"
      centered
      size="lg"
      :title="$t('message.point.titleModal')"
      hide-footer
    >
      <b-card>
        <div id="outer">
          <h4 id="inner">{{ localeDate(Date()) + " - " + time }}</h4>
          <br />
        </div>
        <app-timeline>
          <!--Start-->

          <li
            v-bind="$attrs"
            class="timeline-item"
            :class="[`timeline-variant-primary`]"
            v-on="$listeners"
          >
            <div
              class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
            >
              <feather-icon icon="UserIcon" />
            </div>

            <slot>
              <div
                class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
              >
                <h6 v-text="$t('message.point.startDayLabel')" />
                <div v-if="dayTimeLine[0].label">
                  <small
                    class="timeline-item-time text-nowrap text-muted"
                    v-text="dayTimeLine[0].time"
                  />
                </div>
                <div v-else>
                  <button
                    v-if="dayTimeLine[0].button"
                    type="button"
                    class="btn btn-success btn-sm"
                    @click="savePoint('startDay')"
                  >
                    {{ $t("message.point.checkPoint") }}
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btn-success btn-sm"
                    style="cursor: not-allowed"
                    disabled
                  >
                  {{ $t("message.point.checkPoint") }}
                  </button>
                  <button
                    v-if="dayTimeLine[0].button"
                    type="button"
                    class="ml-1 btn btn-warning btn-sm"
                    v-b-modal.modal-late-start
                  >
                  {{ $t("message.point.late") }}
                  </button>
                  <button
                    v-else
                    type="button"
                    class="ml-1 btn btn-warning btn-sm"
                    style="cursor: not-allowed"
                  >
                  {{ $t("message.point.late") }}
                  </button>
                </div>
              </div>
              <p class="mb-2" v-text="$t('message.point.startDayDesc')" />
            </slot>
          </li>

          <!--End day-->

          <li
            v-bind="$attrs"
            class="timeline-item"
            :class="[`timeline-variant-success`]"
            v-on="$listeners"
          >
            <div
              class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
            >
              <feather-icon icon="AwardIcon" />
            </div>

            <slot>
              <div
                class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
              >
                <h6 v-text="$t('message.point.endDaylabel')" />
                <div v-if="dayTimeLine[1].label">
                  <small
                    class="timeline-item-time text-nowrap text-muted"
                    v-text="dayTimeLine[1].time"
                  />
                </div>
                <div v-else>
                  <button
                    v-if="dayTimeLine[1].button"
                    type="button"
                    class="btn btn-success btn-sm"
                    @click="savePoint('endDay')"
                  >
                  {{ $t("message.point.checkPoint") }}
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btn-success btn-sm"
                    style="cursor: not-allowed"
                    disabled
                  >
                  {{ $t("message.point.checkPoint") }}
                  </button>
                  <button
                    v-if="dayTimeLine[1].button"
                    type="button"
                    class="ml-1 btn btn-warning btn-sm"
                    v-b-modal.modal-late-end
                  >
                  {{ $t("message.point.late") }}
                  </button>
                  <button
                    v-else
                    type="button"
                    class="ml-1 btn btn-warning btn-sm"
                    style="cursor: not-allowed"
                  >
                  {{ $t("message.point.late") }}
                  </button>
                </div>
              </div>
              <p class="mb-2" v-text="$t('message.point.endDayDesc')" />
            </slot>
          </li>
        </app-timeline>
      </b-card>
    </b-modal>

    <b-modal
      id="modal-late-start"
      modal-class="modal-primary"
      centered
      title="Ponto manual"
      no-close-on-backdrop
      hide-footer
    >
      <div>
        <h4>{{ $t("message.point.tellTime") }}</h4>
        <cleave
          id="time"
          v-model="startHourLate"
          class="form-control"
          :raw="false"
          :options="timeOptions"
          placeholder="hh:mm:ss"
        />
      </div>
      <br />
      <div>
        <h4>{{ $t("message.point.tellReason") }}</h4>
        <v-select
          v-model="startHourLateReason"
          :options="pointOptions"
          :reduce="(val) => val.value"
        />
      </div>

      <br />

      <b-row>
        <b-col>
          <div class="text-right">
            <b-button @click="hideModal('modal-late-start')" class="mr-1"
              >{{ $t("message.point.cancel") }}</b-button
            >

            <b-button @click="savePointLate('startDay')" variant="primary"
              >{{ $t("message.point.checkPoint") }}</b-button
            >
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-late-end"
      modal-class="modal-primary"
      centered
      title="Ponto manual"
      no-close-on-backdrop
      hide-footer
    >
      <div>
        <h4>{{ $t("message.point.tellTime") }}</h4>
        <cleave
          id="time"
          v-model="endHourLate"
          class="form-control"
          :raw="false"
          :options="timeOptions"
          placeholder="hh:mm:ss"
        />
      </div>
      <br />
      <div>
        <h4>{{ $t("message.point.tellReason") }}</h4>
        <v-select
          v-model="endHourLateReason"
          :options="pointOptions"
          :reduce="(val) => val.value"
        />
      </div>

      <br />

      <b-row>
        <b-col>
          <div class="text-right">
            <b-button @click="hideModal('modal-late-end')" class="mr-1"
              >{{ $t("message.point.cancel") }}</b-button
            >

            <b-button @click="savePointLate('endDay')" variant="primary"
              >{{ $t("message.point.checkPoint") }}</b-button
            >
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BSpinner,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import usepointList from "./usePointList";
import PointListAddNew from "./PointListAddNew.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import Ripple from "vue-ripple-directive";
import JsonExcel from "vue-json-excel";
import Vue from "vue";
import AccessControl from "@core/utils/access-control";
import BCardCode from "@core/components/b-card-code";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "./AppTimelineItem.vue";
import flatPickr from "vue-flatpickr-component";
import dateInput from "@/@core/utils/dateInput";
import date from "@/@core/utils/date";
import store from "@/store";
Vue.component("downloadExcel", JsonExcel);
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Cleave from "vue-cleave-component";

export default {
  components: {
    PointListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BSpinner,
    flatPickr,
    Cleave,

    BCardCode,
    AppTimeline,
    AppTimelineItem,
    BTooltip,
  },

  directives: {
    Ripple,
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInput(newVal);
    },
  },

  data() {
    return {
      pointList: [],
      totalRegisters: 0,
      perPage: 0,
      currentPage: 1,
      isBusy: false,
      isBusyButton: false,
      showPagination: true,
      allDataSize: 9999,

      currentPointData: [],
      pointOptions: [],

      startHourLateReason: '',
      startHourLate: '',

      endHourLateReason: '',
      endHourLate: '',

      startDay: '',
      endDay: '',

      timeOptions: {
        time: true,
        timePattern: ["h", "m", "s"],
      },

      //Clock
      interval: null,
      time: null,

      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,

      latitude: "",
      longitude: "",
      location: null,
      gettingLocation: false,
      errorStr: null,

      flatConfig: {
        dateFormat: "d/m/Y",
      },

      daySelected: "",
      //Timeline
      dayTimeLine: [
        { label: false, button: false, time: "" },
        { label: false, button: false, time: "" },
      ],
    };
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  computed: {
    tableColumns() {
      const lang = this.$i18n.locale;
      if (!lang) {
        return [];
      }

      return [
        { key: "user", sortable: false, label: this.getColLabel("user") },
        { key: "date", sortable: false, label: this.getColLabel("date") },
        {
          key: "startDay",
          sortable: false,
          label: this.getColLabel("startDay"),
        },
        { key: "endDay", sortable: false, label: this.getColLabel("endDay") },
        { key: "total", sortable: false, label: this.getColLabel("total") },
      ];
    },
  },

  async created() {
    this.isBusyButton = true; //Previnir que carregue o botão sem ter finalizada a consulta.

    //Starting timeline
    this.dayTimeLine[0].label = false;
    this.dayTimeLine[0].button = true;

    this.options();

    //Geo
    await this.getUserLocation();

    await this.getPointList();

    this.interval = setInterval(() => {
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);

    //checar todos os lançamentos do dia por usuário
    await this.checkAllPointsByDay();
    this.isBusyButton = false;
  },

  mounted() {
    this.$root.$on("newListPoint", (newListPoint) => {
      this.pointList = newListPoint.content;
      this.totalRegisters = newListPoint.totalElements;
      this.perPage = newListPoint.numberOfElements;
      this.currentPage = 1;
    });

    if (this.$i18n.locale) {
      this.flatConfig = dateInput(this.$i18n.locale);
    }
  },

  methods: {
    async getUserLocation() {
      this.gettingLocation = true;
      try {
        this.gettingLocation = false;
        this.location = await this.getLocation();

        this.latitude = this.location.coords.latitude;
        this.longitude = this.location.coords.longitude;
      } catch (e) {
        this.gettingLocation = false;
        this.errorStr = e.message;
      }
    },

    hideModal(id) {
      this.$root.$emit("bv::hide::modal", id);
    },

    async options() {
      var optionsList = [];
      await axios
        .get(`${URL_API}point_options`, { headers: getHeader(this.userData) })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            const element = response.data[i].option;
            optionsList.push(element);
            this.pointOptions.push({
              label: optionsList[i],
              value: optionsList[i],
            });
          }
        });
    },

    async getLocation() {
      return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
          reject(new Error("Geolocation is not available."));
        }

        navigator.geolocation.getCurrentPosition(
          (pos) => {
            resolve(pos);
          },
          (err) => {
            reject(err);
          }
        );
      });
    },

    async daySelectedFilter() {
      if (this.daySelected === "" && !this.searchQuery) {
        // sem filtro ou busca

        this.getPointList();
      } else if (this.daySelected != "" && !this.searchQuery) {
        // com filtro sem busca

        var d = date(this.$i18n.locale, this.daySelected);

        var month = d.getUTCMonth() + 1;
        var day = d.getUTCDate();
        var year = d.getUTCFullYear();

        var newdate = year + "-" + month + "-" + day;

        if (!AccessControl("point_all")) {
          this.isBusy = true;
          await axios
            .get(
              `${URL_API}point/${this.userData.id}/searchByDay?searchTerm=${newdate}`,
              {
                headers: getHeader(this.userData),
              }
            )
            .then((response) => {
              this.pointList = [];
              if (response.data != []) {
                for (var i = 0; i < response.data.length; i++) {
                  this.pointList.push(response.data[i]);
                }
              }
              this.isBusy = false;
              this.showPagination = false;
            });
        } else {
          this.isBusy = true;
          await axios
            .get(`${URL_API}point/searchByDay?searchTerm=${newdate}`, {
              headers: getHeader(this.userData),
            })
            .then((response) => {
              this.pointList = [];
              if (response.data != []) {
                for (var i = 0; i < response.data.length; i++) {
                  this.pointList.push(response.data[i]);
                }
              }
              this.isBusy = false;
              this.showPagination = false;
            });
        }
      } else if (this.daySelected === "" && this.searchQuery) {
        // com busca sem filtro
        this.getPointFilter();
      } else {
        // com busca com filtro

        var d = date(this.$i18n.locale, this.daySelected);

        var month = d.getUTCMonth() + 1;
        var day = d.getUTCDate();
        var year = d.getUTCFullYear();

        var newdate = year + "-" + month + "-" + day;

        if (!AccessControl("point_all")) {
          this.isBusy = true;
          await axios
            .get(
              `${URL_API}point/${this.userData.id}/${this.searchQuery}/${newdate}`,
              {
                headers: getHeader(this.userData),
              }
            )
            .then((response) => {
              this.pointList = [];
              if (response.data != []) {
                for (var i = 0; i < response.data.length; i++) {
                  this.pointList.push(response.data[i]);
                }
              }
              this.isBusy = false;
              this.showPagination = false;
            });
        } else {
          this.isBusy = true;
          await axios
            .get(`${URL_API}point/${this.searchQuery}/${newdate}`, {
              headers: getHeader(this.userData),
            })
            .then((response) => {
              this.pointList = [];
              if (response.data != []) {
                for (var i = 0; i < response.data.length; i++) {
                  this.pointList.push(response.data[i]);
                }
              }
              this.isBusy = false;
              this.showPagination = false;
            });
        }
      }
    },

    savePoint(item) {
      var now = new Date();

      if (item === "startDay") {
        this.dayTimeLine[0].label = true;
        this.dayTimeLine[0].button = false;
        this.dayTimeLine[0].time = this.getCurrentTime(now);

        this.dayTimeLine[1].label = false;
        this.dayTimeLine[1].button = true;

        this.submitNewpoint(item, now, false);
      } else if (item === "endDay") {
        this.dayTimeLine[1].label = true;
        this.dayTimeLine[1].button = false;
        this.dayTimeLine[1].time = this.getCurrentTime(now);

        this.submitNewpoint(item, now, false);
      }
    },

    rowClass(item) {
      if (item != null || item != undefined) {
        if (item.startLate || item.endLate) {
          if (item.approved) {
            return "";
          }
          return "table-warning";
        } else {
          return "";
        }
      }
    },

    savePointLate(item) {
      if (item === "startDay") {
        if (
          this.startHourLate === undefined ||
          this.startHourLate === "" ||
          this.startHourLateReason === ""
        ) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Erro",
              icon: "XIcon",
              variant: "danger",
              text: "Dados inválidos",
            },
          });
        } else {
          var hour = this.startHourLate.split(":")[0];
          var minute = this.startHourLate.split(":")[1];
          var second = this.startHourLate.split(":")[2];

          var now = new Date();
          now.setHours(hour);
          now.setMinutes(minute);
          now.setSeconds(second);

          this.dayTimeLine[0].label = true;
          this.dayTimeLine[0].button = false;
          this.dayTimeLine[0].time = this.getCurrentTime(now);

          this.dayTimeLine[1].label = false;
          this.dayTimeLine[1].button = true;

          this.$root.$emit("bv::hide::modal", "modal-late-start");
          this.submitNewpoint(item, now, true);
        }
      } else if (item === "endDay") {
        if (
          this.endHourLate === undefined ||
          this.endHourLate === "" ||
          this.endHourLateReason === ""
        ) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Erro",
              icon: "XIcon",
              variant: "danger",
              text: "Dados inválidos",
            },
          });
        } else {
          var hour = this.endHourLate.split(":")[0];
          var minute = this.endHourLate.split(":")[1];
          var second = this.endHourLate.split(":")[2];

          var now = new Date();
          now.setHours(hour);
          now.setMinutes(minute);
          now.setSeconds(second);

          this.dayTimeLine[1].label = true;
          this.dayTimeLine[1].button = false;
          this.dayTimeLine[1].time = this.getCurrentTime(now);

          this.$root.$emit("bv::hide::modal", "modal-late-end");
          this.submitNewpoint(item, now, true);
        }
      }
    },

    async checkAllPointsByDay() {
      await axios
        .get(`${URL_API}point/today/${this.userData.id}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.currentPointData = [];
          this.currentPointData = response.data;

          if (response.data.startDay != null) {
            this.dayTimeLine[0].label = true;
            this.dayTimeLine[0].button = false;
            this.dayTimeLine[0].time = this.getCurrentTime(
              new Date(response.data.startDay)
            );

            this.dayTimeLine[1].label = false;
            this.dayTimeLine[1].button = true;
          }
          if (response.data.endDay != null) {
            this.dayTimeLine[1].label = true;
            this.dayTimeLine[1].button = false;
            this.dayTimeLine[1].time = this.getCurrentTime(
              new Date(response.data.endDay)
            );
          }
        });
    },

    async allData() {
      const response = await axios.get(
        `${URL_API}point/list?page=0&sort=id,${this.paramData.listSort}&size=${this.allDataSize}`,
        { headers: getHeader(this.userData) }
      );
      return response.data.content;
    },

    async submitNewpoint(item, now, late) {
      var pointData = [];

      if (item === "startDay") {
        var startLate = false;
        var startLateReason = "";

        if (late) {
          startLate = true;
          startLateReason = this.startHourLateReason;
        }

        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1;
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        var newdate = year + "-" + month + "-" + day;

        pointData = [
          {
            userId: this.userData.id,
            userEmail: this.userData.email,
            type: item,
            day: newdate,
            startDay: now,
            locationStartDay: {
              latitude: this.latitude,
              longitude: this.longitude,
            },
            startLate: startLate,
            startLateReason: startLateReason,
            tenant: this.userData.tenant,
          },
        ];

        axios({
          method: "post",
          url: `${URL_API}point`,
          headers: getHeader(this.userData),
          data: pointData[0],
        }).then((response) => {
          this.pointList = [];
          this.getPointList();
          this.currentPointData = response.data; //Setando o id do ponto atual
        });
      }

      if (item === "endDay") {
        var endLate = false;
        var endLateReason = "";

        if (late) {
          endLate = true;
          endLateReason = this.endHourLateReason;
        }

        pointData = [
          {
            endDay: now,
            endLate: endLate,
            endLateReason: endLateReason,
            locationEndDay: {
              latitude: this.latitude,
              longitude: this.longitude,
            },
          },
        ];

        axios({
          method: "put",
          url: `${URL_API}point/${this.currentPointData.id}/endDay`,
          headers: getHeader(this.userData),
          data: pointData[0],
        }).then((response) => {
          this.pointList = [];
          this.startHourLateReason = "";
          this.startHourLate = "";
          this.endHourLateReason = "";
          this.endHourLate = "";
          this.getPointList();
        });
      }
    },

    getCurrentTime(now) {
      return (
        this.localeDate(now) +
        " - " +
        now.getHours() +
        ":" +
        now.getMinutes() +
        ":" +
        now.getSeconds()
      );
    },

    getTimeLate(now, value) {
      return this.localeDate(now) + " - " + value;
    },

    getHour(date) {
      return (
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
      );
    },

    getDay(day) {
      return this.localeDate(day);
    },

    getColLabel(colName) {
      return this.$i18n.t("message.point." + colName);
    },

    openViewPage(row) {
      row._showDetails = !row._showDetails;
      this.$router.push({ name: "apps-point-view", params: { id: row.id } });
    },

    restrictions(value) {
      return AccessControl(value);
    },

    startDownload() {
      this.isBusy = true;
    },
    finishDownload() {
      this.isBusy = false;
    },

    async getPointList() {
      if (!AccessControl("point_all")) {
        this.isBusy = true;
        await axios
          .get(
            `${URL_API}point/${this.userData.id}/list?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.totalRegisters = response.data.totalElements;
            this.pointList = response.data.content;
            this.perPage = response.data.numberOfElements;
            this.isBusy = false;
          });

        if (this.pointList === null) {
          this.showPagination = false;
        } else {
          this.showPagination = true;
          this.currentPage = 1;
        }
      } else {
        this.isBusy = true;
        await axios
          .get(
            `${URL_API}point/list?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}`,
            {
              headers: getHeader(this.userData),
            }
          )
          .then((response) => {
            this.totalRegisters = response.data.totalElements;
            this.pointList = response.data.content;
            this.perPage = response.data.numberOfElements;
            this.isBusy = false;
          });

        if (this.pointList === null) {
          this.showPagination = false;
        } else {
          this.showPagination = true;
          this.currentPage = 1;
        }
      }
    },

    async eventPage(currentPage) {
      if (!AccessControl("point_all")) {
        this.isBusy = true;
        await axios
          .get(
            `${URL_API}point/${this.userData.id}/list?page=${
              currentPage - 1
            }&size=${this.paramData.tableSize}`,
            { headers: getHeader(this.userData) }
          )
          .then((response) => {
            this.pointList = response.data.content;
            this.isBusy = false;
          });

        if (this.pointList === null) {
          this.showPagination = false;
        } else {
          this.showPagination = true;
        }
      } else {
        this.isBusy = true;
        await axios
          .get(
            `${URL_API}point/list?page=${currentPage - 1}&size=${
              this.paramData.tableSize
            }`,
            { headers: getHeader(this.userData) }
          )
          .then((response) => {
            this.pointList = response.data.content;
            this.isBusy = false;
          });

        if (this.pointList === null) {
          this.showPagination = false;
        } else {
          this.showPagination = true;
        }
      }
    },

    async getPointFilter() {
      if (this.daySelected != "") {
        this.daySelectedFilter();
      } else {
        if (!AccessControl("point_all")) {
          this.isBusy = true;
          await axios
            .get(
              `${URL_API}point/${this.userData.id}/search?searchTerm=${this.searchQuery}`,
              {
                headers: getHeader(this.userData),
              }
            )
            .then((response) => {
              this.pointList = [];
              if (response.data != []) {
                for (var i = 0; i < response.data.length; i++) {
                  this.pointList.push(response.data[i]);
                }
              }
              this.isBusy = false;
              this.showPagination = false;
            });
        } else {
          this.isBusy = true;
          await axios
            .get(`${URL_API}point/search?searchTerm=${this.searchQuery}`, {
              headers: getHeader(this.userData),
            })
            .then((response) => {
              this.pointList = [];
              if (response.data != []) {
                for (var i = 0; i < response.data.length; i++) {
                  this.pointList.push(response.data[i]);
                }
              }
              this.isBusy = false;
              this.showPagination = false;
            });
        }
      }
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(new Date(value), "short", this.$i18n.locale);
      }
      return this.$i18n.t("message." + "noDate");
    },

    searchWord() {
      if (this.searchQuery) {
        this.getPointFilter();
      } else if (this.daySelected != "") {
        this.getPointFilter();
      } else {
        this.getPointList();
      }
    },
  },

  setup() {
    const isAddNewPointSidebarActive = ref(false);

    const {
      perPage,
      currentPage,
      totalpoint,
      dataMeta,
      searchQuery,
      refpointListTable,
    } = usepointList();

    return {
      isAddNewPointSidebarActive,
      perPage,
      currentPage,
      totalpoint,
      dataMeta,
      searchQuery,
      refpointListTable,
      avatarText,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

#inner {
  width: 50%;
  margin: 0 auto;
}

#outer {
  width: 100%;
  text-align: center;
}

.my-17 {
  margin-top: 17.2rem;
  margin-bottom: 17.2rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

@import "~@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
@import "~@core/scss/base/components/include"; // Components includes

// Color palettes
@import "~@core/scss/base/core/colors/palette-variables.scss";

$timeline-border-color: $border-color;

/* Generate:
*  Apply background color to dot
*/
@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type== "base" {
      .timeline-variant-#{$color_name} {
        &.timeline-item-fill-border-#{$color_name} {
          border-color: $color_value !important;
          &:last-of-type {
            &:after {
              background: linear-gradient($color_value, $white);
            }
          }
        }
        .timeline-item-point {
          background-color: $color_value;

          &:before {
            background-color: rgba($color_value, 0.12);
          }
        }

        .timeline-item-icon {
          color: $color_value;
          border: 1px solid $color_value;
        }
      }
    }
  }
}

.timeline-item {
  padding-left: 2.5rem;
  position: relative;
  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-left: 1px solid $timeline-border-color;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient($timeline-border-color, $white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: "";
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    z-index: 1;
  }
}

// *===============================================---*
// *--------- Dark Layout ---------------------------------------*
// *===============================================---*

.dark-layout {
  .timeline-item {
    &:last-of-type {
      &:after {
        background: linear-gradient(
          $theme-dark-border-color,
          $theme-dark-card-bg
        );
      }
    }
    &:not(:last-of-type) {
      border-left-color: $theme-dark-border-color;
    }

    .timeline-item-icon {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
