<template>
  <b-sidebar
    id="add-new-point-sidebar"
    :visible="isAddNewPointSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-point-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ $t("message.functionalities.createNewFunctionalities") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewpoint())"
          @reset.prevent="resetForm"
        >
          <!-- Type -->
          <validation-provider
            #default="validationContext"
            name="tipo"
            rules="required"
          >
            <b-form-group
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.tasks.type") }}
              </template>
              <v-select
                v-model="pointData.type"
                :options="typeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="type-status"
              />
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("message.buttons.create") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,

    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewPointSidebarActive",
    event: "update:is-add-new-point-sidebar-active",
  },
  props: {
    isAddNewPointSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,

      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,
    };
  },

  created() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.pointData.latitude = position.coords.latitude;
        this.pointData.longitude = position.coords.longitude;
      },
      (error) => {
        console.log(error.message);
      }
    );
  },

  methods: {
    async submitNewpoint() {
      var now = new Date();

      this.pointData.userId = this.userData.id;
      this.pointData.time = now;
      this.pointData.tenant = this.userData.tenant;

      axios({
        method: "post",
        url: `${URL_API}point`,
        headers: getHeader(this.userData),
        data: this.pointData,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.create"),
              icon: "CheckIcon",
              variant: "success",
              text: `Funcionalidade criada com sucesso`,
            },
          });
        })
        .then(() => {
          axios
            .get(
              `${URL_API}point/list?page=0&size=${this.paramData.tableSize}`,
              {
                headers: getHeader(this.userData),
              }
            )
            .then((response) => {
              this.$root.$emit("newListPoint", response.data);
              this.onSubmit();
            });
        });
    },
  },

  setup(props, { emit }) {
    const blankpointData = {};

    const pointData = ref(JSON.parse(JSON.stringify(blankpointData)));
    const resetpointData = () => {
      pointData.value = JSON.parse(JSON.stringify(blankpointData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-point-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetpointData);

    return {
      pointData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-point-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
